import { useApi } from "../../api";
import { useEffect, useState } from "react";
import { Alert, Button, SearchQuoteInput, SearchQuoteResults } from "../../components";
import { usePageLoadingContext } from "../../contexts/PageLoadingContext";
import { SEARCH_QUOTE_RESPONSE, SearchQuoteRequest, SearchQuoteResponse } from "../../api/types";
import { BASE_APP_PAth, API_ERROR_MESSAGE } from "../../api/launchpad-api";
import { InitialSearchInputs, InitialInputErrorMessages } from "../../api/types/search-quote-request";
import { QuoteInfo } from "../../api/types/search-quote-response";
import { useNavigate } from "react-router-dom";
import { storage } from "../../lib/storage";
import { AuthContext, useAuthContext } from "../../contexts/AuthContextProvider";

type SearchQuoteProps = {
    startNewSession: (selectedZip: null, selectedStateCode: null, zipVerified: boolean, selectedProduct: undefined) => void;
}
export const SearchQuote: React.FC<SearchQuoteProps> = ({ startNewSession }) => {
    const api = useApi();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ title: string; message: React.ReactNode }>({ title: "", message: "" });
    const { showLoader, hideLoader } = usePageLoadingContext();
    const [validationErrors, setValidationErrors] = useState(InitialInputErrorMessages);
    const [searchInputs, setSearchInputs] = useState<SearchQuoteRequest>(InitialSearchInputs);
    const [searchResults, setSearchResults] = useState<QuoteInfo[]>([]);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [showNewCallButton, setShowNewCallButton] = useState(false);
    const [showModal, setShowModal] = useState(false); // State to control the modal visibility
    const userType = useAuthContext().getUserType;
    const userToken = useAuthContext().getAuthToken;

    useEffect(() => {
        if (storage.exist("agentSearchResult")) {
            const storedQuotes = storage.load("agentSearchResult");
            setSearchResults(storedQuotes);
            setShowSearchResults(true);
        }
    }, []);

    const isSearchButtonDisabled = (): boolean => {
        return !isSubmissionValid(searchInputs) || !isEmailValid(searchInputs.emailId);
    }

    function isEmailValid(email: string): boolean {
        if (!email) {
            return true; 
        }
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    }

    function clearAlertMessage() {
        setAlertMessage({
            title: "",
            message: ""
        });
    }

    function submitForm(): void {
        showLoader();
        setSearchResults([]);
        setValidationErrors(InitialInputErrorMessages);
        if (!isSubmissionValid(searchInputs)) {
            setAlertMessage({
                title: "Invalid Submission",
                message: "Please provide valid information according to the submission rules."
            });
            hideLoader();
            return;
        }
        api.commercialAgentApi.getSearchQuote({
            ...searchInputs,
            userType: userType()
        }, userToken())
            .then((quoteResponse) => {
                if (quoteResponse.type !== SEARCH_QUOTE_RESPONSE || quoteResponse.data.quoteInfos.length === 0) {
                    setShowSearchResults(false);
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Quote Not Found",
                        message: (
                            <div>
                                Quote may be too old or no longer available. Please try your search again or simply begin a&nbsp;
                                <span
                                    role="button"
                                    tabIndex={0}
                                    style={{ cursor: 'pointer', textDecoration: 'underline', color: '#3773ca', fontWeight: 'bold' }}
                                    onClick={() => startNewQuote()}
                                >
                                    new quote.
                                </span>
                            </div>
                        )
                    });
                    hideLoader();
                    return;
                }
                setShowAlert(false);
                let quoteInfos: QuoteInfo[] = quoteResponse.data.quoteInfos;
                setSearchResults(quoteInfos);
                storage.save("agentSearchResult", quoteInfos);
                storage.save("searchQuoteZip", searchInputs.zipCode);
                setShowSearchResults(true);
                hideLoader();
            }, (error) => {
                setShowAlert(true);
                setAlertMessage({
                    title: "Thank you for your request.",
                    message: API_ERROR_MESSAGE
                });
                hideLoader();
            });
        if (!showNewCallButton) {
            storage.save("searchedOnce", true);
            setShowNewCallButton(true);
        }
        setShowModal(true);
    }

    // Define a function to check if the submission is valid
    function isSubmissionValid(inputs: SearchQuoteRequest) {
        const hasReferenceNum = inputs.referenceNumber.length > 0;
        const hasLastName = inputs.lastName.length > 0;
        const hasDob = inputs.dateOfBirth.length > 0;
        const hasEmail = inputs.emailId.length > 0;
        const hasPhone = inputs.phoneNumber.length > 0;
        const hasZipCode = inputs.zipCode.length > 0;

        // Check if any of the valid combinations are met
        if (hasReferenceNum || hasPhone || hasEmail || (hasLastName && hasDob && hasZipCode)) {
            return true;
        }

        // If none of the conditions are met, the submission is invalid
        return false;
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let errorMessage = "";

        // Define validation logic for each input
        if (name === "dateOfBirth") {
            const dobRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;
            if (value.length < 10 || !dobRegex.test(value)) {
                errorMessage = "Please enter date as MM/DD/YYYY";
            }
        } else if (name === "zipCode") {
            const zipCodeRegex = /^[0-9]{5}$/;
            if (value.length < 5 || !zipCodeRegex.test(value)) {
                errorMessage = "Please enter a valid 5-digit ZIP code.";
            }
        }
        setValidationErrors(prev => ({
            ...prev,
            [name]: errorMessage
        }));
        setSearchInputs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let errorMessage = "";

        // Define validation logic for each input
        if (name === "emailId") {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (!emailRegex.test(value)) {
                errorMessage = "Please enter email address in this format: Name@service.com";
            }
        } else if (name === "phoneNumber") {
            const phoneRegex = /(^[0-9]{10}$)|(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)/;
            if (value.length < 10 || !phoneRegex.test(value)) {
                errorMessage = "Oops, it looks like you may need to enter a valid phone number or use the following format xxx-xxx-xxxx.";
            }
        }
        setValidationErrors(prev => ({
            ...prev,
            [name]: errorMessage
        }));
    }


    function resetForm(): void {
        setSearchInputs(InitialSearchInputs);
        setSearchResults([]);
        setShowSearchResults(false);
        setShowAlert(false);
        setShowNewCallButton(false);
        setValidationErrors(InitialInputErrorMessages);
        clearAlertMessage();
        storage.remove("agentSearchResult");
        storage.remove("searchQuoteZip");
        storage.remove("searchedOnce");
        setShowModal(false);
    }

    function startNewQuote(): void {
        showLoader();
        startNewSession(null, null, false, undefined);
        navigate(BASE_APP_PAth + "/zip-selection");
    }


    return (
        <div>
            <div className="search-quote-result-container">
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        {(showAlert && (alertMessage.title || alertMessage.message) &&
                            <Alert
                                title={alertMessage.title}
                                content={alertMessage.message}
                            // closeAlert={() => clearAlertMessage()}
                            />
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12" >
                        <h2 className="page-header-left" style={{ padding: "1rem", marginBottom: "1rem" }}>
                            Recall and Quote Start
                        </h2>
                    </div>
                </div>
                <form id="search-quote-form" >
                    <div className="row">
                        <div className="col-lg-4">
                            <SearchQuoteInput
                                label="Quote Number"
                                name="referenceNumber"
                                type="text"
                                value={searchInputs.referenceNumber}
                                onChange={handleInput} />
                        </div>
                        <div className="col-lg-4">
                            <SearchQuoteInput
                                label="Email Address"
                                name="emailId"
                                type="text"
                                value={searchInputs.emailId}
                                onChange={handleInput}
                                onBlur={handleBlur}
                                error={validationErrors.emailId} />
                        </div>
                        <div className="col-lg-4">
                            <SearchQuoteInput
                                label="Phone number"
                                name="phoneNumber"
                                type="tel"
                                placeholder="(___)___-____"
                                value={searchInputs.phoneNumber}
                                onChange={handleInput}
                                onBlur={handleBlur}
                                error={validationErrors.phoneNumber} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <SearchQuoteInput
                                label="Last Name"
                                name="lastName"
                                type="text"
                                value={searchInputs.lastName}
                                onChange={handleInput} />
                        </div>
                        <div className="col-lg-4">
                            <SearchQuoteInput
                                label="5-Digit Zip Code"
                                name="zipCode"
                                type="tel"
                                value={searchInputs.zipCode}
                                onChange={handleInput}
                                error={validationErrors.zipCode} />
                        </div>
                        <div className="col-lg-4">
                            <SearchQuoteInput
                                label="Date of Birth"
                                name="dateOfBirth"
                                type="tel"
                                value={searchInputs.dateOfBirth}
                                onChange={handleInput}
                                error={validationErrors.dateOfBirth} />
                        </div>
                    </div>
                    <div className="row" style={{ display: "none" }}>   {/* Hide the Line of Business and FirstName for now */}
                        <div className="col-lg-4">
                            <SearchQuoteInput
                                label="First Name"
                                name="firstName"
                                type="text"
                                value={searchInputs.firstName}
                                onChange={handleInput} />
                        </div>
                        <div className="col-lg-4">
                            {/* defaults to Commercial for day one */}
                            <SearchQuoteInput
                                label="Line of Business"
                                name="lineOfBusiness"
                                type="select"
                                // value={searchInputs.lineOfBusiness}
                                onChange={handleInput}
                                // error={validationErrors.lineOfBusiness} 
                                options={[
                                    { value: "Commercial", label: "Commercial" }
                                ]}
                                inputType="select"
                            />
                        </div>
                    </div>
                </form>
                <div className="row mt-10" >
                    <div className="col-sm-12">
                        <Button
                            className="btn--primary nav-button-back mt-10  xs:w-full sm:w-full"
                            children="Start New Quote"
                            onClick={(e) => startNewQuote()}
                        ></Button>
                        <Button
                            className="btn--primary nav-button-back mt-10  xs:w-full sm:w-full"
                            children="Reset Form"
                            onClick={(e) => resetForm()}
                        ></Button>
                        <Button disabled={isSearchButtonDisabled()}
                            className="btn--primary nav-button-back mt-10  xs:w-full sm:w-full"
                            children="Search"
                            onClick={(e) => submitForm()}
                        ></Button>
                        {storage.exist("searchedOnce") && <Button
                            className="btn--secondary nav-button-back mt-10  xs:w-full sm:w-full"
                            children="New Call"
                            onClick={(e) => { resetForm() }}
                        ></Button>}

                    </div>
                </div>
            </div >
            <div className="search-quote-result-container">
                {showSearchResults &&
                    <SearchQuoteResults quoteInfos={searchResults} />
                }
            </div >
        </div>
    );
};