type props = {
   headerLabel?: string;
  label?: string;  
  labelClass?: string;
  iconClass?: string;
  p1?: string;
  p2?: string;
  p3?: string;
  urlText?: string;
  url?: string;
};

export const Disclaimer: React.FunctionComponent<props> = ({
  headerLabel,
  label,  
  labelClass,
  iconClass,
  p1,
  p2,
  p3,
  urlText,
  url,
}) => {
  return (
      <div className="disclaimer" style={ {padding:"3rem", maxHeight: "28rem"}}>
          {iconClass && (
              <div>
                  <span className={`${iconClass} geico-icon disclaimer-icon`}></span>
              </div>
          )}
          <div>
              {headerLabel && <label className="text bold">{headerLabel}</label>}
              {label && <label className={labelClass}>{label}</label>}
              <label className={labelClass}>
                <br/>
                <br/>
                When you click on “Start Quote,” “Continue,” “Start Your Quote Now,” or “Next,” you may be taken to the website of one of the insurance companies or wholesalers with which GEICO Insurance Agency, LLC works. Please note that this website is not owned or controlled by GEICO.<u> The information you provide to GEICO will be shared with those companies so that they can return a quote.</u> Each insurer or wholesaler has sole financial responsibility for its own products. GEICO has no control over the privacy practices of these companies and assumes no responsibility in connection with your use of their websites. Any information that you directly provide to one of these companies is subject to the privacy policy posted on its website. All products offered are written on an admitted basis unless explicitly noted that the quote offering is on non-admitted paper. Coverages are written through non-GEICO insurance companies and are secured through the GEICO Insurance Agency, LLC 
                </label>
              {p1 && <p>{p1}</p>}
              {p2 && <p>{p2}</p>}
              {p3 && <p>{p3}</p>}
              {urlText && <a href={url}>{urlText}</a>}
          </div>
      </div>
  );
};
