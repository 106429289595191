import { useApi } from "../api";
import { useNavigate } from "react-router-dom";
import Pagination from './pagination';
import { QuoteInfo } from "../api/types/search-quote-response";
import { useEffect, useState } from "react";
import Modal from './quote-summary-popup-modal';
import { MktPlaceQuoteSummary } from '../pages/mktplace-quote-smry/mktplace-quote-summary';
import { PARTNER_DETAILS_RESPONSE } from "../api/types/partner-details";

export const SearchQuoteResults = ({ quoteInfos }: { quoteInfos: QuoteInfo[] }) => {
    const api = useApi();
    const [currentPage, setCurrentPage] = useState(1);
    const [quotesPerPage, setQuotesPerPage] = useState(5);
    const [currentQuotes, setCurrentQuotes] = useState<QuoteInfo[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState<QuoteInfo | null>(null);
    const [carrierOptions, setCarrierOptions] = useState<{ [key: string]: { redirectUrl: string, imageUrl: string } }>({});

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setQuotesPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const navigate = useNavigate();
    function getMktQuoteSummary(refNum: QuoteInfo): void {
        setSelectedQuote(refNum);
        setShowModal(true);
    }

    useEffect(() => {
        const indexOfLastQuote = currentPage * quotesPerPage;
        const indexOfFirstQuote = indexOfLastQuote - quotesPerPage;
        setCurrentQuotes(quoteInfos.slice(indexOfFirstQuote, indexOfLastQuote));
    }, [currentPage, quotesPerPage, quoteInfos]);

    useEffect(() => {
        api.commercialAgentApi.getPartnerData()
            .then(response => {
                if (response.type === PARTNER_DETAILS_RESPONSE) {
                    const options = response.data.reduce((acc: { [key: string]: { redirectUrl: string, imageUrl: string } }, carrier) => {
                        acc[carrier.name] = {
                            redirectUrl: carrier.redirectUrl,
                            imageUrl: carrier.imageUrl
                        };
                        return acc;
                    }, {});
                    setCarrierOptions(options);
                } else {
                    console.error("Error fetching carrier codes:", response);
                }
            })
            .catch(error => {
                console.error("Error fetching carrier codes:", error);
            });
    }, []);

    const handleReferenceClick = (quote: QuoteInfo) => {
        const carrier = carrierOptions[quote.source];
        const redirectUrl = quote.redirectUrl || (carrier && carrier.redirectUrl);
        if (redirectUrl) {
            window.open(`${redirectUrl}`);
        } else {
            console.error("No redirect URL found for carrier:", quote.source);
        }
    };

    return (
        <div>
            <div className="data-table sticky-two-column">
                <table className="table">
                    <thead>
                        <tr>
                            <th role="columnheader">#Quote</th>
                            <th className="sort-header" role="columnheader">Full Name</th>
                            <th className="sort-header" role="columnheader">Business Name</th>
                            <th className="sort-header" role="columnheader">Carrier Name</th>
                            <th className="sort-header" role="columnheader">State</th>
                            <th className="sort-header" role="columnheader">Line of Business</th>
                            <th className="sort-header" role="columnheader">Quoted Premium</th>
                            <th role="columnheader">View Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentQuotes.map((quote, index) => (
                            <tr key={index}>
                                <th className="sticky-column">
                                    <a href="#" onClick={() => handleReferenceClick(quote)}>
                                        {quote.referenceNumber}
                                    </a>
                                </th>
                                <td>{quote.firstName + " " + quote.lastName}</td>
                                <td>{quote.businessName}</td>
                                <td>{quote.source}</td>
                                <td>{quote.stateCode}</td>
                                <td>{quote.lineOfBusiness.join(", ")}</td>
                                <td>${quote.premium}</td>
                                <th className="col--edit-control">
                                    <div onClick={() => getMktQuoteSummary(quote)}>
                                        <span className="geico-icon geico-icon--small geico-icon--actionable icon-edit" ></span>
                                    </div>
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="table-footer">
                <div className="form-field">
                    <label className="text">Rows per page</label>
                    <div className="select">
                        <select onChange={handleRowsPerPageChange} value={quotesPerPage}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(quoteInfos.length / quotesPerPage)}
                    onPageChange={handlePageChange}
                />
            </div>
            <Modal show={showModal} onClose={() => setShowModal(false)}>
            {selectedQuote && <MktPlaceQuoteSummary quoteInfo={selectedQuote} carrierOptions={carrierOptions} />}
            </Modal>
        </div>
    );
};