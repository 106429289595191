export const MSAL_SET_USER_TYPE = "MSAL_SET_USER_TYPE";
export const MSAL_LOGIN = "MSAL_LOGIN";
export const MSAL_LOGOUT = "MSAL_LOGOUT";

export interface SystemState {
  isLoggedIn: boolean;
  userName: string | null | undefined;
  userType: UserType;
}

interface MsalSilentLoginAction {
    type: typeof MSAL_SET_USER_TYPE;
    userType: UserType;
}

interface MsalLoginAction {
    type: typeof MSAL_LOGIN;
}

interface MsalLogoutAction {
  type: typeof MSAL_LOGOUT;
}

export type SystemActionTypes =
  | MsalLoginAction
  | MsalLogoutAction
  | MsalSilentLoginAction;

export enum UserType {
    Agent = "Agent",
    Customer = "Customer"
}

export enum PageType {
    UI = "UI",
    Error = "Error",
    None ="None"
}