import React from "react";

type props = {
    id: string;
    name: string;
    image: string;
    show: boolean;
    label: string;
    error?: boolean;
    selected?: boolean;
    disabled?: boolean;
    onClick: Function;
    description?: string;
    items?: string[];
};

export const ImageSelectionCard: React.FunctionComponent<props> = ({
    id,
    name,
    image,
    show,
    label,
    selected,
    disabled,
    error,
    onClick,
    description,
    items,
}) => {
    return (
        (show) ?
            <div  {...(disabled && { disabled: true })} 
                className={
                    selected
                        ? "image-selection-card image-selections-selected"
                        : error
                            ? "image-selection-card image-selection-card-error"
                            : disabled
                                ? "image-selection-card image-selection-card-disabled"
                                : "image-selection-card"
                }
                style={{ padding: "0px", maxWidth:"280px" }}
                onClick={() => onClick()}
                role="radio" aria-checked="true" >             
                <span className={selected ? "icon-confirmation" : "radio"}></span>
                <div>
                    <img src={image} alt={label} style={{ borderTopRightRadius: "6px", borderTopLeftRadius: "6px", width: "100%", height: "100%" }} />
                </div>
                <div style={{ alignContent: "left",  padding: "0.5rem", margin: "1rem" }} >                   
                        {label && (
                        <label
                            style={{ textAlign: "left", width: "100%", marginTop: "0px", padding: "0px" }}
                                className={
                                    disabled ? "image-card-label-disabled" : "image-card-label"
                                }
                            >
                                {label}
                            </label>
                        )}                   
                    {description && <div
                        style={{ textAlign: "left", width: "100%", margin:"0rem", marginTop: ".5rem",  padding: "0px" }}
                        className="image-card-description"> {description}</div>}

                    {items && (
                        <ul                          
                            className="image-card-item-container">
                            {items.map((item) => (
                                <li className="image-card-item" key={item}>
                                    <div className="image-card-item=text"> {item}</div>
                                </li>
                            ))}
                        </ul>
                    )}
                    </div>
            </div>
            : null
    );
};