import { useAuthContext } from "../contexts/AuthContextProvider";
import {
    useLocation,
    Navigate,
    Outlet,
} from "react-router-dom";
import { UserType } from "../store/system/types";
import { useMemo } from "react";

export const ProtectedRouter = ({ roles }: { roles: Array<string> }) => {
    const { getUserType, getUserAccount } = useAuthContext();
    const account = getUserAccount()?.username;
    const location = useLocation();
    return useMemo(() => {
        if (getUserType() !== undefined && getUserType() === UserType.Agent) {
            var currentAccount = getUserAccount();
            const rolesMatched = roles
                .filter((role) => {
                    if (currentAccount && currentAccount.idTokenClaims) {
                        const assignedRoles = currentAccount.idTokenClaims['roles'];
                        return assignedRoles?.includes(role);
                    }
                    return false;
                });

            if (rolesMatched && rolesMatched.length > 0) {
                return <Outlet />;
            } else {              
                return <Navigate to="/un-authorised" state={{ from: location }} replace />;
            }
        } else {
            return <Outlet />;
        }
    }, [getUserType(), account]);
}