import { Button } from "../../components/button";
import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { API_ERROR_MESSAGE, BASE_APP_PAth } from "../../api/launchpad-api";
import { AddAgentNoteRequest, AGENT_NOTES_RESPONSE, AgentNotesData, AgentNotesRequest } from "../../api/types/agent-notes-modal";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { Alert } from "../../components";
import { usePageLoadingContext } from "../../contexts/PageLoadingContext";


type props = {
    quoteInfoId: string;
}

export const AgentNotes: React.FunctionComponent<props>  = ({quoteInfoId}) => {
    const api = useApi();
    const authContext = useAuthContext();
    const { showLoader, hideLoader } = usePageLoadingContext();
    const [showAlert, setShowAlert] = useState(false);
    const [agentNotes, setQuoteNotes] = useState<AgentNotesData[]>([]);
    const[agentNoteTextArea,setAgentNoteTextArea] = useState<string>("");
    const [alertMessage, setAlertMessage] = useState({ title: "", message: "" }); 
       

    const handleChange = (e: any) => {
        setAgentNoteTextArea(e.target.value);
    }

    function clearAlertMessage() {
        setAlertMessage({
            title: "",
            message: ""
        });
    }
   
    useEffect(() => {
        getAgentNotes(quoteInfoId);
        window.scrollTo(0, 0);
    }, []);

    function getAgentNotes(quoteInfoId: string) {      
        let request: AgentNotesRequest = { quoteInfoId: quoteInfoId };//
        if (quoteInfoId) {
            showLoader(); 
            api.commercialAgentApi.getAgentNotes(request, authContext.getAuthToken()).then((response) => {
                if (response.type !== AGENT_NOTES_RESPONSE || response.data.partnerQuoteNotes.length === 0) {
                    hideLoader();
                    return;
                }
                let quoteNotes = response.data.partnerQuoteNotes as AgentNotesData[];
                setQuoteNotes(quoteNotes);
                hideLoader();               
            }).catch((error) => {
                setShowAlert(true);
                setAlertMessage({
                    title: "Thank you for your request.",
                    message: API_ERROR_MESSAGE
                });
                hideLoader();
            });
        }
    };
    //converting the date to local date
    function convertUTCDateToLocalDate(date:string) {
        var newDate = new Date(date);
        newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
        return newDate.toLocaleString();
    }
        
    async function submitForm(): Promise<void> {

        //showLoader();
        const userName = authContext.getUserName();
        if(authContext.getUserType() === "Customer"){
            setShowAlert(true);
            setAlertMessage({
                title: "Error",
                message: "Agent notes can only be added by agents."
            });
            //hideLoader();
            setAgentNoteTextArea("");
            window.scrollTo(0, 0);
            return;
        }

        const agentId = authContext.getEmployeeId();
        let request: AddAgentNoteRequest = { quoteInfoId: quoteInfoId, note: agentNoteTextArea, 
            agentId: agentId, agentName: userName?.toString() || "", callReason: "1", createdDate: new Date().toISOString() };
        if (quoteInfoId && agentNoteTextArea) {
            await api.commercialAgentApi.addAgentNote(request,authContext.getAuthToken()).then((response) => {             
                if (response.type !== AGENT_NOTES_RESPONSE) {
                    return;
                }
                getAgentNotes(quoteInfoId);
                setAgentNoteTextArea("");
                //hideLoader();
                return;
            }).catch((error) => {
                setShowAlert(true);
                setAlertMessage({
                    title: "Error",
                    message: API_ERROR_MESSAGE
                });
                //hideLoader();
            });
        }
    }

    return (
    <div className="page-header--wrapper">
        <div className="container" style={{backgroundColor:"#F5F5F5"}} >
        <div className="row">
                <div className="col-sm-12 col-md-12">
                    {(showAlert && (alertMessage.title || alertMessage.message) &&
                        <Alert
                            title={alertMessage.title}
                            content={alertMessage.message}
                        />
                    )}
                </div>
            </div>
            <div style={{maxHeight:"300px",overflowY:"auto"}}>
                <ul>
                    {agentNotes.map((searchResults:AgentNotesData)=>
                    <div style={{marginBottom:"10px"}}>
                        <p style={{marginBottom:"5px"}}>{searchResults.note}</p>
                        <div style={{display: "flex", justifyContent:"flex-end", fontWeight:"bold", fontSize:"small"}}>
                            <label>{searchResults.agentName}</label>&nbsp;&nbsp; 
                            <label>{searchResults.createdDate && convertUTCDateToLocalDate(searchResults.createdDate).toString()}</label>
                        </div>
                        <hr />
                    </div>
                )}
                </ul>
            </div>
            <br />
            <br />  
            <h3>Add Notes</h3> 
            <textarea value={agentNoteTextArea} onChange={handleChange} placeholder="Add agent notes" style={{marginBottom:"0"}}> </textarea>   
            <Button 
                    className="btn--primary nav-button-next mt-10  w-3/5 xs:w-full sm:w-full"
                    children="Add Notes"
                    onClick={(e) => submitForm()}
                    disabled={agentNoteTextArea === "" ? true : false}
            ></Button>
        </div>
    </div>

    );

};
