import React, { useState, useEffect } from "react";
import { Alert, Button, Disclaimer, PopupModal } from "../../components"
import { usePageLoadingContext } from "../../contexts/PageLoadingContext";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store";
import { clearCache, updateQuote, updateQuoteResponse } from "../../store/quote/action";
import { useApi } from "../../api";
import { BusinessKeywordModel, BUSINESS_KEYWORD_RESPONSE, InitBusinessKeywordModel } from "../../api/types/business-keyword-model";
import { BusinessKeywordAutoComplete } from "../../components/business-keyword-autocomplete";
import { API_ERROR_MESSAGE } from "../../api/launchpad-api";
import { SaveQuoteRequest, SAVE_QUOTE_RESPONSE } from "../../api/types";
import useDevice from "../../hooks/useDevice";
import { CarrierRecommendationModel, CARRIER_RECOMMENDATION_RESPONSE, InitCarrierRecommendationModel } from "../../api/types/carrier-recommendation-model";

export const KeywordRecommendation = () => {
    const api = useApi();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ title: "", message: "" });
    const [formError, setFormError] = useState(false);
    const quote = useSelector((state: RootState) => state.quote.quote);
    const { showLoader, hideLoader } = usePageLoadingContext();
    const deviceDetail = useDevice();
    const [keywordError, setKeywordError] = useState("");
    const [keywordList, setkeywordList] = useState<BusinessKeywordModel[]>([]);
    const [selectedKeyword, setSelectedKeyword] = useState<BusinessKeywordModel | null>(InitBusinessKeywordModel);
    const [carrierRecommendation, setCarrierRecommendation] = useState<CarrierRecommendationModel>(InitCarrierRecommendationModel);
    const [recommendedKeywordList, setRecommendedKeywordList] = useState<BusinessKeywordModel[]>([]);
    const [searchKeyword, setSearchKeyword] = useState<string | null>();
    const [defaultView, setDefaultView] = useState<"SearchCardUI" | "DropdownUI">("SearchCardUI");

    useEffect(() => {
        showLoader();
        // setGeicoDtm({ ...geicoDtm, viewName: GeicoPageNames.Launchpad });
        var selectedProducts = quote.data.SelectedProducts;
        var lobCode = selectedProducts && selectedProducts.length > 0 ? selectedProducts[0].LobCode : "";

        if (lobCode && lobCode.length > 1) {
            api.commercialAgentApi.getBusinessKeywords(lobCode).then((result) => {
                if (result.type === BUSINESS_KEYWORD_RESPONSE) {
                    setkeywordList(result.data);
                }
                else {
                    window.scrollTo(0, 0);
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                }
                setFormError(false);
                hideLoader();
            }, (error) => {
                console.error(error);
                hideLoader();
            });
        }
    }, []);

    useEffect(() => {
        if (selectedKeyword && selectedKeyword.id !== "") {
            showLoader();
            let saveQuote: SaveQuoteRequest = {
                ...quote.data,
                BusinessKeyword: selectedKeyword,
                CarrierDetail: null
            };

            api.commercialAgentApi.getCarrierRecommendation(saveQuote)
                .then((response) => {
                    hideLoader();
                    if (response.type === CARRIER_RECOMMENDATION_RESPONSE) {

                        if (response.data.carrier?.toUpperCase() === "BOLDPENGUIN" && response.data.businessClass === null) {
                            setCarrierRecommendation({ ...response.data, businessClass: selectedKeyword.description });
                        }
                        else {
                            setCarrierRecommendation(response.data);
                        }
                        setFormError(false);
                    }
                    else {
                        window.scrollTo(0, 0);
                        setShowAlert(true);
                        setCarrierRecommendation(InitCarrierRecommendationModel);
                        setAlertMessage({
                            title: "Thank you for your request.",
                            message: API_ERROR_MESSAGE
                        });
                    }
                }, (error) => {
                    console.log(error);
                    setShowAlert(true);
                    setCarrierRecommendation(InitCarrierRecommendationModel);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                    hideLoader();
                });
        }
        else {
            setCarrierRecommendation(InitCarrierRecommendationModel);
        }
    }, [selectedKeyword]);

    const isNextButtonDisabled = (): boolean => {
        if (carrierRecommendation && carrierRecommendation.businessClass) {
            return false;
        }
        return true;
    }

    function clearAlertMessage() {
        setAlertMessage({
            title: "",
            message: ""
        });
    }

    const getBusinessKeywordRecommendedKeywordList = () => {
        if (searchKeyword) {
            api.commercialAgentApi.getBusinessKeywordRecommendation(searchKeyword).then((result) => {
                if (result.type === BUSINESS_KEYWORD_RESPONSE) {
                    console.log(result.data);
                    setRecommendedKeywordList(result.data);
                    return;
                }
                else {
                    window.scrollTo(0, 0);
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                }
                setFormError(false);
            }, (error) => {
                console.error(error);
            });
        }
        setRecommendedKeywordList([]);
    }

    const onCardSelect = (item: BusinessKeywordModel) => {
        if (item.id === "0") {
            setDefaultView("DropdownUI");
        }
        else {
            selectedKeyword?.id === item.id
                ? setSelectedKeyword(null)
                : setSelectedKeyword(item)
        }
    }

    const showKeywordSearch = () => {
        return (
            <div className="row">
                <div className="col-sm-12 col-md-12" style={{ margin: 0 }}>
                    <span className="search-field--full-width">Search by keyword e.g. hauling, contractor, florist, etc.</span>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="search-field-wrapper search-field--full-width">
                        <input type="search" id="searchKeyword" name="searchKeyword" autoFocus
                            onChange={(e) => { setSearchKeyword(e.target.value); setRecommendedKeywordList([]);}}    
                            onKeyDownCapture={(e) => e.key === "Enter" && getBusinessKeywordRecommendedKeywordList()}
                            placeholder="Search business by keyword" aria-label="Search" spellCheck="true" />
                    </div>
                </div>
                <div className="col-sm-6 col-md-3" style={{ marginTop: 0 }}>
                    <div className="search-field--full-width" style={{ marginTop: 0 }}>
                        <button type="button" onClick={(e) => getBusinessKeywordRecommendedKeywordList()} className="btn btn--secondary search-field-btn"><span>Search</span></button>
                    </div>
                </div>                
            </div>
        )
    }

    const showRecommendedKeywordCards = () => {
        if (recommendedKeywordList && recommendedKeywordList.length > 0) {
            return (
                <div className="row">
                    <div className="col-sm-12 mb-3p5rem">
                        <form>
                            <div id="recommendedKeywordList" className="card-selection-wrapper">
                                <h5>What does your business do?</h5>
                                <div className="card-selections three-column" aria-labelledby="card-selections-label-id">
                                    {
                                        recommendedKeywordList.map((item, index) => (
                                            <div
                                                onClick={(e) => onCardSelect(item)}
                                                key={index}
                                                className={
                                                    selectedKeyword && item.id === selectedKeyword.id
                                                        ? "card-selections-card card-selections-selected"
                                                        : "card-selections-card"}>
                                                {/* <input type="radio" id={"keyword" + index} name="cardSelections" /> */}
                                                <span></span>
                                                <div className="card-selections-header">
                                                    <label>{item.description}</label>
                                                </div>
                                                <div className="card-selections-content">
                                                    {/* <p></p> */}
                                                </div>
                                                {/* <div className="highlight"><span>Selected</span></div> */}
                                            </div>))
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        }
    }

    function SubmitForm(): void {
        showLoader();
        let saveQuote: SaveQuoteRequest = {
            ...quote.data,
            BusinessKeyword: selectedKeyword,
            CarrierDetail: {
                CarrierCob: carrierRecommendation.businessClass,
                CarrierCode: carrierRecommendation.carrier,
                CarrierName: carrierRecommendation.carrier?.toUpperCase(),
            }
        };

        api.commercialAgentApi.saveQuote(saveQuote, true)
            .then((quoteResponse) => {
                if (quoteResponse.type !== SAVE_QUOTE_RESPONSE) {
                    window.scrollTo(0, 0);
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                    hideLoader();
                    return;
                }
                else {
                    store.dispatch(clearCache());
                    window.open(quoteResponse.data.redirectUrl, "_self");
                }
                setFormError(false);

            }, (error) => {
                setShowAlert(true);
                setAlertMessage({
                    title: "Thank you for your request.",
                    message: API_ERROR_MESSAGE
                });
                hideLoader();
            });
    }

    return (
        <div style={{ alignItems: "center", padding: "1rem", width: "95%" }} className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    {(showAlert && (alertMessage.title || alertMessage.message) &&
                        <Alert
                            title={alertMessage.title}
                            content={alertMessage.message}
                            closeAlert={() => clearAlertMessage()}
                        />
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <h2 className="page-header-left" style={{ padding: "1rem", marginBottom: "1rem" }}>
                        Tell us about your business.
                    </h2>
                </div>
            </div>
            {
                defaultView === "SearchCardUI" ?
                    showKeywordSearch() :
                    (
                        <div className="row" >
                            <div className="col-sm-12 col-md-12">
                                <BusinessKeywordAutoComplete
                                    disabled={false}
                                    label="What does your business do?"
                                    placeholder="Example(s) contractor, painter etc"
                                    type="text"
                                    defaultValue={quote.data.BusinessKeyword?.id ?? ""}
                                    suggestions={keywordList}
                                    onItemChange={(selectedItem) => { setKeywordError(""); setSelectedKeyword(selectedItem) }}
                                    error={keywordError} />
                            </div>
                        </div>)
            }
            {
                defaultView === "SearchCardUI" ?
                    showRecommendedKeywordCards() : null
            }

            <div className="row mt-10" >
                <div className="col-sm-12 col-md-6">
                    <Button style={{ display: "none" }}
                        className="btn--secondary nav-button-back mt-10 w-3/5 xs:w-full sm:w-full"
                        onClick={(e) => window.history.go(-1)}
                    >Back</Button>
                </div>

                <div className="col-sm-12 col-md-6">
                    <Button disabled={isNextButtonDisabled()}
                        className="btn--primary nav-button-next mt-10  w-3/5 xs:w-full sm:w-full"
                        onClick={(e) => SubmitForm()}
                    >Continue</Button>
                </div>
            </div>
            <div className="row" >
                <div className="col-sm-12 col-md-12" style={{ marginBottom: "40px" }}>
                    <Disclaimer
                        headerLabel="Legal Notices"
                        label="The information you provide will be shared with selected insurance companies to obtain a policy. If GEICO is unable to match you with one of our trusted partners, you may opt to be referred to Commercial Insurance Center (&#8220;CIC&#x201C;), to help find a commercial insurance solution for your business. Your information will not be shared with CIC without your consent." />
                </div>
                <div className="col-sm-12 col-md-12">
                </div>
            </div>
        </div>
    );
};
