import { AuthLoginRequest } from "./types/auth-login-request";
import { Account, AuthError, AuthResponse, UserAgentApplication } from 'msal';
import { storage } from "../lib/storage";


const loginRequest : AuthLoginRequest = {
    scopes: ["User.Read"]
};

const authCallBack = (error: AuthError, response?: AuthResponse) => {
    if (error) {
        console.log(error);
    } else {
        if (response?.tokenType === "id_token") {
            console.log("id_token acquired at: " + new Date().toString());
        } else if (response?.tokenType === "access_token") {
            console.log("access_token acquired at: " + new Date().toString());
        } else {
            console.log("token type is:" + response?.tokenType);
        }
    }
}

export const authAPI = (msal: UserAgentApplication) => ({
  authApi: {
    login: (): void =>
        msal.loginRedirect(loginRequest),
    silentLogin: (): void =>
        msal.acquireTokenRedirect(loginRequest),
    logout: (): void =>
        msal.logout(),
    getAccount: (): Account => 
        msal.getAccount(),
    registerAuthCallBack: () => {
        msal.handleRedirectCallback(authCallBack);
    },
  },
});
