export const SEARCH_QUOTE_RESPONSE = "SEARCH_QUOTE_RESPONSE"

export type QuoteInfo = {
    id: string;
    stateCode: string;
    lineOfBusiness: string[];
    email: string;
    dateOfBirth: string;
    lastName: string;
    zipCode: string;
    phoneNumber: string;
    referenceNumber: string;
    source: string;
    userType: string;
    token: string;
    firstApplicationFormID: string;
    premium: string;
    quoteCreatedDate: string;
    quoteUpdatedDate: string;
    redirectUrl: string;
    //New fields
    firstName: string;
    businessName: string;
    businessAddress: string;
    businessClassDescription: string;
};

export type Notice = {
    noticeType: string;
    messageId: string;
    message: string;
    severity: string;
    sourceProperty: string;
    referringId: string;
    sourceService: string;
};

export type SearchQuoteResponse = {
    type: typeof SEARCH_QUOTE_RESPONSE;
    data: {
        quoteInfos: QuoteInfo[];
        notices: Notice[];
    };
};