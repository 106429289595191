import { type } from "os";
import {
  BusinessKeywordModel,
  CarrierDetailModel,
} from "./business-keyword-model";

export const SAVE_QUOTE_REQUEST = "SAVE_QUOTE_REQUEST";

export class Lob {
  public LobCode: string = "";
  public LobName: string = "";
  public SspCode: string = "";
  public AdobeDtmName: string = "";
}

export class CommercialQuote {
  public LaunchpadId: string = "";
  public Zip: string = "";
  public StateCode: string = "";
  public Soa: string = "";
  public SourceCorrelationId: string = "";
  public SelectedProducts: Lob[];
  public BusinessKeyword: BusinessKeywordModel | null;
  public CarrierDetail: CarrierDetailModel | null;
  public IsComplete: boolean = false;

  public constructor() {
    this.Zip = "";
    this.LaunchpadId = "";
    this.StateCode = "";
    this.BusinessKeyword = null;
    this.SelectedProducts = [];
    this.CarrierDetail = null;
  }
}

export class SaveQuoteRequest extends CommercialQuote {
  public Id: string = "";
  public UserType: string = "Customer";
  public UserId: string = "LPCustomer";
  public ApplicationSource: string = "COMMERCIALLAUNCHPAD";

  constructor() {
    super();
    this.Id = "";
    this.UserType = "Customer";
    this.UserId = "LPCustomer";
    this.ApplicationSource = "COMMERCIALLAUNCHPAD";
  }
}

export class LoggingResourceModel {
  public ErrorMessage: string | undefined = "";
  public ErrorDetails: string = "";
  public Title: string = "";
}
