import { createRoot } from "react-dom/client";
import { BrowserRouter, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import './index.css'
import '@gdk/full/dist/styles/gdk-full.min.css';
import '@gdk/navigation/dist/styles/gdk-navigation.min.css';
import SectionLoader from './components/sectionLoader'
import { PageLoadingContextProvider } from './contexts/PageLoadingContext'
import { ClientConfigContextProvider } from './contexts/ClientConfigContext'
import { AdobeAnalyticsContextProvider } from './contexts/AdobeAnalyticsContext'
import { Main  } from "./Main";
import { AutoRedirectContextProvider } from "./contexts/AutoRedirectContext";

function render() {
    const container = document.getElementById("root")!;
    const root = createRoot(container);
   
    root.render(<Provider store={store}>
        <PageLoadingContextProvider>
            <ClientConfigContextProvider>
                <AutoRedirectContextProvider>
                <AdobeAnalyticsContextProvider>
                    <SectionLoader />
                    <BrowserRouter>                     
                            <Main />                     
                    </BrowserRouter>
                    </AdobeAnalyticsContextProvider>
                    </AutoRedirectContextProvider>
            </ClientConfigContextProvider>
        </PageLoadingContextProvider>
    </Provider>);
}

render();