import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventPayload,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { loginRequest } from "../config/authConfig";
import { PageType } from "../store/system/types";
import { useApi } from "../api";

export const useMsalHelper = (msalInstance: PublicClientApplication) => {
  var api = useApi();
  const handleTokenResponse = (
    tokenResponse: AuthenticationResult | null,
    renderUiCallbackFunction: (pageType: PageType) => void
  ): void => {
    if (!tokenResponse) {
      const callbackAccounts: Array<AccountInfo> =
        msalInstance.getAllAccounts();
      if (callbackAccounts.length === 0) {
        // No user signed in
        msalInstance.loginRedirect(loginRequest);
      } else {
        renderUiCallbackFunction(PageType.UI);
      }
    } else {
      renderUiCallbackFunction(PageType.UI);
    }
  };

  const parseToAccountInfo = (
    payload: EventPayload
  ): AccountInfo | null | undefined => {
    if (payload && "account" in payload) {
      return payload.account;
    }
    return null;
  };

  const handleMsalEventCallback = (
    renderUiCallbackFunction: (pageType: PageType) => void
  ): void => {
    msalInstance.addEventCallback((event: EventMessage) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        let currentAccount = parseToAccountInfo(event.payload);
        if (typeof currentAccount !== "undefined") {
          msalInstance.setActiveAccount(currentAccount);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        api.commercialAgentApi
          .logError({
            ErrorMessage: event.error?.message,
            Title: "Login failure from MSAL",
            ErrorDetails: "msal login failure",
          })
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
        renderUiCallbackFunction(PageType.Error);
      }
    });
  };

  const hadnleMsalRedirectPomise = (
    renderUiCallbackFunction: (pageType: PageType) => void
  ): void => {
    msalInstance
      .handleRedirectPromise()
      .then((tokenResponse) =>
        handleTokenResponse(tokenResponse, renderUiCallbackFunction)
      )
      .catch((err) => {
        console.error(err);
        api.commercialAgentApi
          .logError({
            ErrorMessage: "msal token response error",
            Title: "Token response error MSAL",
            ErrorDetails: "msal token response error",
          })
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
        renderUiCallbackFunction(PageType.Error);
      });
  };

  return { handleMsalEventCallback, hadnleMsalRedirectPomise };
};
