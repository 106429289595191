import axios from 'axios'
import * as MSAL from 'msal';
import { getAuthConfigurationSettings } from '../config/auth-config';

import { authAPI } from './auth-api'
import { launchPadAPI } from './launchpad-api'

const commercialAgentApi = axios.create()

const msal = new MSAL.UserAgentApplication({
    auth: getAuthConfigurationSettings()
});

const Api = () => ({
    ...launchPadAPI(commercialAgentApi),
    ...authAPI(msal)
})

export const useApi = () => {
    return Api()
}
