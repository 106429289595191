import { storage } from '../../lib/storage'
import { QuoteActionTypes, IQuoteState, QuoteState, Update_QUOTE_DATA, Update_QUOTE_RESPONSE } from "./types"

export const QUOTE_CACHE = "QUOTE_CACHE";

export const QuoteReducer = (state: IQuoteState = new QuoteState(), action: QuoteActionTypes): IQuoteState => {
    switch (action.type) {
        case 'INIT_QUOTE_CACHE': {           
            let newState: IQuoteState = {
                ...state,                
                quote: {                   
                    isError: false,
                    isLoading: false,
                    errorMessage: "",
                    redirectUrl: "",                    
                    data: {
                        ...action.quote                        
                    },                  
                }
            }
            storage.save(QUOTE_CACHE, newState, 5)
            return newState;
        } 
            
        case Update_QUOTE_DATA: {
            let newState: IQuoteState = {
                ...state,
                quote: {
                    isError: false,
                    isLoading: false,
                    errorMessage: "",
                    data: {                        
                        ...state.quote.data,
                        ...action.quote,                        
                    },
                    redirectUrl:""
                }
            }
            storage.save(QUOTE_CACHE, newState, 5)
            return newState;
        }

        case Update_QUOTE_RESPONSE: {
            let newState: IQuoteState = {
                ...state,
                quote: {
                    isError: false,
                    isLoading: false,
                    errorMessage: "",
                    data: {
                        ...state.quote.data,                        
                        LaunchpadId: action.response.data.launchpadId
                    },
                    redirectUrl: action.response.data.redirectUrl,
                }
            }
            storage.save(QUOTE_CACHE, newState, 5)
            return newState;
        }

        case 'SET_LOADING': {
            return {
                ...state,               
                quote: {
                    ...state.quote,
                    isLoading: action.isLoading,
                    isError: false,                
                    errorMessage: "",
                    redirectUrl: "",
                }               
            };           
        }

        case 'SET_ERROR': {
            return {
                ...state,
                quote: {
                    ...state.quote,
                    isError: true,
                    errorMessage: action.errorMessage,
                    isLoading: false,                  
                    redirectUrl: "",
                }
            };
        }

        case 'CLEAR_ERROR': {
            return {
                ...state,
                quote: {
                    ...state.quote,
                    isError: false,
                    errorMessage: "",
                    isLoading: false,                                        
                    redirectUrl: "",
                }
            };
        }

        case 'CLEAR_CACHE': {
            let newState: IQuoteState = new QuoteState();
            storage.remove("QUOTE_CACHE");            
            return newState;            
        }

        default:
            {
                if (storage.exist(QUOTE_CACHE)) {
                    try {
                        var localState : IQuoteState = storage.load(QUOTE_CACHE);
                        return localState;
                    }
                    catch (error) {
                        console.log("Init State Error:" + error);
                    }
                }
                storage.save(QUOTE_CACHE, state, 5)
                return state;
            }
    }
}
