import { SaveQuoteRequest, CommercialQuote, Lob } from "../../api/types";
import { SaveQuoteResponse } from "../../api/types/quote-response";

export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_CACHE = "CLEAR_CACHE";
export const INIT_QUOTE_CACHE = "INIT_QUOTE_CACHE";
export const Update_QUOTE_RESPONSE = "Update_QUOTE_RESPONSE";
export const Update_QUOTE_DATA = "Update_QUOTE_DATA";
export const SET_LOADING = "SET_LOADING";

export interface IQuoteState {
    quote: Quote;   
}

export class Quote {
    public data: SaveQuoteRequest;
    public redirectUrl: string;
    public isLoading: boolean;
    public isError: boolean;
    public errorMessage: string;

    constructor() {
        this.data = new SaveQuoteRequest();       
        this.redirectUrl = "";
        this.isLoading = false;
        this.isError = false;
        this.errorMessage = "";        
    }
}
export class QuoteState implements IQuoteState {
    public quote: Quote; 

    constructor() {
        this.quote = new Quote();       
    }
}

interface SetQuoteCacheAction {
    type: typeof INIT_QUOTE_CACHE;
    quote: SaveQuoteRequest;
}

interface UpdateQuoteCacheAction {
    type: typeof Update_QUOTE_RESPONSE;
    response: SaveQuoteResponse;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    errorMessage: string;
}

interface ClearErrorAction {
    type: typeof CLEAR_ERROR    
}

interface ClearCacheAction {
    type: typeof CLEAR_CACHE
}

interface SetLoadingAction {
    type: typeof SET_LOADING,
    isLoading: boolean
}

interface UpdateQuoteAction {
    type: typeof Update_QUOTE_DATA,
    quote: CommercialQuote
}
export type QuoteActionTypes = UpdateQuoteAction| SetQuoteCacheAction | SetErrorAction | ClearErrorAction | SetLoadingAction | UpdateQuoteCacheAction | ClearCacheAction;
