import React from "react";
import { useNavigate } from "react-router-dom";
import { AgentNotes } from "./agent-notes";
import { QuoteInfo } from "../../api/types/search-quote-response";
import { BASE_APP_PAth } from "../../api/launchpad-api";

interface MktPlaceQuoteSummaryProps {
    quoteInfo: QuoteInfo;
    carrierOptions: { [key: string]: { redirectUrl: string, imageUrl: string } };
}

export const MktPlaceQuoteSummary: React.FC<MktPlaceQuoteSummaryProps> = ({ quoteInfo, carrierOptions }) => {
    const navigate = useNavigate();
    const carrierImageUrl = process.env.PUBLIC_URL + "/assets/partner-logos/" + carrierOptions[quoteInfo.source]?.imageUrl;

    function convertUTCDateToLocalDate(date: string) {
        var newDate = new Date(date);
        newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
        return newDate.toLocaleString();
    }

    const handleReferenceClick = () => {
        const redirectUrl = quoteInfo.redirectUrl || carrierOptions[quoteInfo.source]?.redirectUrl;
        if (redirectUrl) {
            window.open(`${redirectUrl}`);
        } else {
            console.error("No redirect URL found for carrier:", quoteInfo.source);
        }
    };

    return (
        <>
            <div className="page-header--wrapper">
                <div className="container" style={{ maxWidth: "75%", backgroundColor: "#F5F5F5" }}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-header page-header-bottom-border">
                                <div className="page-header-left">
                                    <pre>
                                        <h3>
                                            Quote# (
                                            <a href="#" onClick={handleReferenceClick}>
                                                {quoteInfo.referenceNumber}
                                            </a>
                                            )
                                        </h3>
                                        <h4>
                                            Premium: {quoteInfo.premium === "" ? "" : "$" + quoteInfo.premium} Source: {quoteInfo.source}
                                        </h4>
                                    </pre>
                                </div>
                                <div className="page-header-right">
                                    {carrierImageUrl && <img src={carrierImageUrl} alt="Carrier Logo" style={{ maxHeight: "50px" }} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ float: "right", width: "40%" }}>
                        <h2 style={{ textAlign: "left", paddingLeft: "3rem" }}> Notes Section</h2>
                        <AgentNotes quoteInfoId={quoteInfo.id} />
                    </div>
                    <div style={{ float: "left", width: "60%" }}>
                        <ul className="list list--two-column" style={{ marginRight: "70px", marginLeft: "50px" }}>
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Full Name</div>
                                <div>{quoteInfo.firstName}  {quoteInfo.lastName}</div>
                            </li>
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Quote Start Date</div>
                                <div>{new Date(quoteInfo.quoteCreatedDate).toLocaleDateString()}</div>
                            </li>
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Date of Birth</div>
                                <div>{quoteInfo.dateOfBirth}</div>
                            </li>
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Email Address</div>
                                <div>{quoteInfo.email}</div>
                            </li>
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Phone Number</div>
                                <div>{quoteInfo.phoneNumber}</div>
                            </li>
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Business Information</div>
                                <div>
                                    <dl>
                                        <dd>{quoteInfo.businessName}</dd>
                                        <dd>{quoteInfo.businessAddress}</dd>
                                        <dd>{quoteInfo.stateCode}&nbsp;&nbsp;{quoteInfo.zipCode}</dd>
                                    </dl>
                                </div>
                            </li>
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Business Class Description </div>
                                <div>{quoteInfo.businessClassDescription}</div>
                            </li>
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Line of Business</div>
                                <div>{quoteInfo.lineOfBusiness.join(", ")}</div>
                            </li>
                            {/* <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Product Type</div>
                                <div>{ }</div>
                            </li> */}
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Quote Last Modify Date</div>
                                <div>{new Date(quoteInfo.quoteUpdatedDate).toLocaleDateString()}</div>
                            </li>
                            {/* <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Quote Status</div>
                                <div>{ }</div>
                            </li>
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Quote Declination Reason</div>
                                <div>{ }</div>
                            </li> */}
                            <li style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                <div>Most Recent IA/Customer Session login date/time</div>
                                <div>{convertUTCDateToLocalDate(quoteInfo.quoteUpdatedDate).toString()}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};