/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

type props = React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    className?: string;
    labelClass?: string;
    containerClass?: string;
    error?: string;
    geoLocate: Function;
    innerRef?: any;
    loading?: boolean;
    disabled?: boolean;
};

export const GeolocationInput: React.FunctionComponent<props> = ({
    className,
    label,
    labelClass,
    containerClass,
    error,
    geoLocate,
    innerRef,
    ...props
}) => {
    return (
        <div 
            className={
                error
                    ? `${containerClass} bundle-zip-container text form-field--error`
                    : `${containerClass} bundle-zip-container text `
            }
        >
            {label && (
                <label htmlFor={props.name} className={`text ${labelClass}`}>
                    {label}
                </label>
            )}
            <div className="zip-code-form">
                <input {...props} ref={innerRef} className={`${className} zip-code-input`} />               
            </div>
            {error &&  <span className="form-message">{error}</span>}
        </div>
    );
};
