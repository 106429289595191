import React from 'react';

interface PaginationProps {
	currentPage: number;
	totalPages: number;
	onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
	return (
		<div className="pagination">
			<ul>
				<li className={currentPage === 1 ? "disabled" : ""}>
					<a href="#" aria-label="First" className="icon-chevron-double-left" onClick={(e) => { e.preventDefault(); onPageChange(1); }}></a>
				</li>
				<li className={currentPage === 1 ? "disabled" : ""}>
					<a href="#" aria-label="Previous" className="icon-chevron-left" onClick={(e) => { e.preventDefault(); onPageChange(currentPage - 1); }}></a>
				</li>
				{[...Array(totalPages).keys()].map(pageNumber => (
					<li key={pageNumber} className={currentPage === pageNumber + 1 ? "pagination-active" : ""}>
						<a href="#" onClick={(e) => { e.preventDefault(); onPageChange(pageNumber + 1); }}>
							{pageNumber + 1}
						</a>
					</li>
				))}
				<li className={currentPage === totalPages ? "disabled" : ""}>
					<a href="#" aria-label="Next" className="icon-chevron-right" onClick={(e) => { e.preventDefault(); onPageChange(currentPage + 1); }}></a>
				</li>
				<li className={currentPage === totalPages ? "disabled" : ""}>
					<a href="#" aria-label="Last" className="icon-chevron-double-right" onClick={(e) => { e.preventDefault(); onPageChange(totalPages); }}></a>
				</li>
			</ul>
		</div>
	);
};

export default Pagination;