import { AuthConfigSettings } from './types/auth-config-settings'
import Configuration from './configuration.json'

const getAuthority = () => "https://login.microsoftonline.com/7389d8c0-3607-465c-a69f-7d4426502912";

const getRedirectUrl = () => window.location.origin;

const getClientId = () => {
    let env = getCurrentEnvironment();
    switch (env) {
        case "DV1": { return Configuration.ENVIRONMENT_SETTINGS.DV1.CLIENT_ID; }
        case "IN1": { return Configuration.ENVIRONMENT_SETTINGS.IN1.CLIENT_ID; }
        case "UT1": { return Configuration.ENVIRONMENT_SETTINGS.UT1.CLIENT_ID; }
        case "EU1": { return Configuration.ENVIRONMENT_SETTINGS.EU1.CLIENT_ID; }
        case "SB1": { return Configuration.ENVIRONMENT_SETTINGS.SB1.CLIENT_ID; }
        case "LT1": { return Configuration.ENVIRONMENT_SETTINGS.LT1.CLIENT_ID; }
        case "LT8": { return Configuration.ENVIRONMENT_SETTINGS.LT8.CLIENT_ID; }
        case "TR1": { return Configuration.ENVIRONMENT_SETTINGS.TR1.CLIENT_ID; }
        case "FT1": { return Configuration.ENVIRONMENT_SETTINGS.FT1.CLIENT_ID; }
        default: { return Configuration.ENVIRONMENT_SETTINGS.PD1.CLIENT_ID; }
    }
}

const getCurrentEnvironment = () => {
    let url = window.location.hostname.toUpperCase();
    if (url.indexOf("LOCALHOST") !== -1) { return "DV1"; }
    if (url.indexOf("DV1") !== -1) { return "DV1"; }
    if (url.indexOf("IN1") !== -1) { return "IN1"; }
    if (url.indexOf("UT1") !== -1) { return "UT1"; }
    if (url.indexOf("EU1") !== -1) { return "EU1"; }
    if (url.indexOf("SB1") !== -1) { return "SB1"; }
    if (url.indexOf("LT1") !== -1) { return "LT1"; }
    if (url.indexOf("LT8") !== -1) { return "LT8"; }
    if (url.indexOf("TR1") !== -1) { return "TR1"; }
    if (url.indexOf("FT1") !== -1) { return "FT1"; }
    return "PD1";
}

export const getAllowMultipleSelection = () => {
    let env = getCurrentEnvironment();
    switch (env) {
        case "DV1": { return Configuration.ENVIRONMENT_SETTINGS.DV1.ALLOW_MULTIPLE_SELECT; }
        case "IN1": { return Configuration.ENVIRONMENT_SETTINGS.IN1.ALLOW_MULTIPLE_SELECT; }
        case "UT1": { return Configuration.ENVIRONMENT_SETTINGS.UT1.ALLOW_MULTIPLE_SELECT; }
        case "EU1": { return Configuration.ENVIRONMENT_SETTINGS.EU1.ALLOW_MULTIPLE_SELECT; }
        case "SB1": { return Configuration.ENVIRONMENT_SETTINGS.SB1.ALLOW_MULTIPLE_SELECT; }
        case "LT1": { return Configuration.ENVIRONMENT_SETTINGS.LT1.ALLOW_MULTIPLE_SELECT; }
        case "LT8": { return Configuration.ENVIRONMENT_SETTINGS.LT8.ALLOW_MULTIPLE_SELECT; }
        case "TR1": { return Configuration.ENVIRONMENT_SETTINGS.TR1.ALLOW_MULTIPLE_SELECT; }
        case "FT1": { return Configuration.ENVIRONMENT_SETTINGS.FT1.ALLOW_MULTIPLE_SELECT; }
        default: { return Configuration.ENVIRONMENT_SETTINGS.PD1.ALLOW_MULTIPLE_SELECT; }
    }
}

export const getAuthConfigurationSettings = (): AuthConfigSettings => {
    let authConfigSettings: AuthConfigSettings = {
        authority: getAuthority(),
        clientId: getClientId(),
        redirectUri: getRedirectUrl(),
        postLogoutRedirectUri: getRedirectUrl(),
    }
    return authConfigSettings;
}