import React, { useState, useEffect } from "react";
import { Alert, Button, Disclaimer, ImageSelectionCard } from "../../components"
import { useApi } from "../../api";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store";
import { getSelectedCommercialProducts } from "../../config/product-config";
import { CommercialProduct } from "../../config/types/product-config-settings";
import { Lob, SaveQuoteRequest, SAVE_QUOTE_RESPONSE } from "../../api/types";
import { clearCache, updateQuote, updateQuoteResponse } from "../../store/quote/action";
import { GeicoPageNames, IGeicoDtm, useAdobeAnalytics } from "../../contexts/AdobeAnalyticsContext";
import { usePageLoadingContext } from "../../contexts/PageLoadingContext";
import { PopupModal } from "../../components/popup-modal";
import useDevice from "../../hooks/useDevice";
import { BASE_APP_PAth, API_ERROR_MESSAGE } from "../../api/launchpad-api";
import { useNavigate } from "react-router-dom";
import { useClientConfigs } from "../../contexts/ClientConfigContext";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useAutoRedirectContext } from "../../contexts/AutoRedirectContext";

export const ProductSelection = () => {
    const api = useApi();
    const navigate = useNavigate();
    const clientConnfig = useClientConfigs();
    const quote = useSelector((state: RootState) => state.quote.quote);
    const authContext = useAuthContext()
    const [commercialProducts, setCommercialProducts]
        = useState(getSelectedCommercialProducts(clientConnfig.userType, quote.data.SelectedProducts, authContext.getUserRoles()));
    const [formError, setFormError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ title: "", message: "" });
    const { showLoader, hideLoader, pageState } = usePageLoadingContext();
    const loading = pageState.sectionLoading;
    const [productError, setProductError] = useState("");
    const { geicoDtm, setGeicoDtm, sendToAdobe } = useAdobeAnalytics();
    //const [redirectToPartner, setRedirectToPartner] = useState("");
    // const [showPopUpConsent, setShowPopUpConsent] = useState(false);
    const deviceDetail = useDevice();
    const redirectContext = useAutoRedirectContext();

    useEffect(() => {
        try {
            showLoader();
            setCommercialProducts(
                getSelectedCommercialProducts(
                    clientConnfig.userType,
                    quote.data.SelectedProducts,
                    authContext.getUserRoles()));
            setTimeout(() => {
                if (redirectContext.skipProductSelection) {
                    // Save and skip or redirect when product already exist in Url
                    onNextClick();
                }
                else {
                    hideLoader();
                }
            }, 200);
        }
        catch (err) {
            console.error(err);
            hideLoader();
        }
    }, []);

    useEffect(() => {
        let selectedProduct = commercialProducts?.find(item => item.selected);
        // verifyRedirectPartner(selectedProduct);

        if (productError !== "" || selectedProduct == undefined) {
            setFormError(true);
        }
        else {
            setFormError(false);
        }

        var selectedProducts = getSelectedProducts();
        store.dispatch(updateQuote({ ...quote.data, SelectedProducts: selectedProducts }));

        // Adobe Geico DTM update zip state         
        setGeicoDtm({
            ...geicoDtm,
            viewName: GeicoPageNames.Launchpad,
            product: selectedProducts && selectedProducts.length > 0 ? commercialProducts.find(p => p.lobCode === selectedProducts[0].LobCode)?.adobeDtmName : "",
            crossSellType: "",
            crossSellOffer: "",
            serviceMilestone: []
        });

    }, [productError, commercialProducts]);

    function clearAlertMessage() {
        setAlertMessage({
            title: "",
            message: ""
        });
    }

    const setProduct = (product: CommercialProduct) => {
        let updatedcommercialProducts: CommercialProduct[] = [];
        // Allow Single Item or Multiple       
        updatedcommercialProducts = commercialProducts.map(item =>
        (item.id === product.id ?
            { ...item, selected: !product.selected, disabled: false }
            : { ...item, selected: false, disabled: false }))
        setCommercialProducts(updatedcommercialProducts);
        setProductError("");
        setFormError(false);
    }

    const getSelectedProducts = (): Lob[] => {
        let selectedProducts: Lob[] = [];
        if (commercialProducts) {
            commercialProducts.forEach(item => {
                if (item.selected) {
                    let selectedlob = new Lob();
                    selectedlob.LobCode = item.lobCode;
                    selectedlob.LobName = item.lobName;
                    selectedlob.SspCode = item.sspCode
                    selectedProducts.push(selectedlob);
                }
            });
        }

        return selectedProducts;
    }

    const submitForm = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        await onNextClick()
    }

    const onNextClick = async () => {
        if (!formError) {
            showLoader();
            const selectedProducts = getSelectedProducts();

            const saveQuoteRequest: SaveQuoteRequest = {
                ...quote.data,
                SelectedProducts: selectedProducts,
                BusinessKeyword: null
            };
            //store.dispatch(updateQuote(saveQuoteRequest));
            // Adobe Geico DTM Track call
            // Init adobe Dtm
            const adobeDtm: IGeicoDtm =
            {
                ...geicoDtm,
                viewName: GeicoPageNames.Launchpad,
                zip: saveQuoteRequest.Zip,
                state: saveQuoteRequest.StateCode,
                crossSellType: "",
                product: commercialProducts.flatMap(p => p.selected === true ? p.adobeDtmName : []).join(','),
                serviceMilestone: geicoDtm.serviceMilestone,
            };

            setGeicoDtm(adobeDtm);
            sendToAdobe(); // Send
            // End of Adobe DTM

            let isComplete = true;

            if (commercialProducts.find(p => p.selected && p.askBusinessKeyword)) {
                isComplete = false;
            }

            if (!clientConnfig.isMultiplePartnerRecommendationEnabled && clientConnfig.userType === "Agent") {
                isComplete = true;
            }

            await api.commercialAgentApi.saveQuote(saveQuoteRequest, isComplete)
                .then((quoteResponse) => {
                    if (quoteResponse && quoteResponse.type !== SAVE_QUOTE_RESPONSE) {
                        window.scrollTo(0, 0);
                        setShowAlert(true);
                        setAlertMessage({
                            title: "Thank you for your request.",
                            message: API_ERROR_MESSAGE
                        });
                        hideLoader();
                        return;
                    }

                    if (quote.data) {
                        store.dispatch(updateQuoteResponse(quoteResponse));
                    }
                    if (!isComplete) {
                        navigate(BASE_APP_PAth + "/business-keyword");
                    }
                    else {
                        if (quoteResponse.data.redirectUrl && quoteResponse.data.redirectUrl.length > 0) {
                            const redirectUrl = quoteResponse.data.redirectUrl;
                            store.dispatch(clearCache());
                            window.open(redirectUrl, "_self");
                        }
                    }
                    setFormError(false);

                }, (error) => {
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                    hideLoader();
                });
        }
    };

    // const showConsent = () => {
    //     let headerText = "Thank you for visiting GEICO"
    //     let disclaimerText = "You're leaving geico.com and being taken to our partner's website " + redirectToPartner + ", to get your quote started.";
    //     return <PopupModal modalId="consent-model"
    //         onClosed={() => setShowPopUpConsent(false)}
    //         showModal={showPopUpConsent}
    //         modalHeadline={headerText}
    //         autoShow={false}
    //         isBackNav={false}
    //         modalType={"dialogueBox"}
    //         overlayShouldCloseModal={true}
    //         showCloseButton={true} >
    //         {disclaimerText}
    //         <div className="modal--call-to-action-bar">
    //             <button type="button" onClick={submitForm}
    //                 className={deviceDetail.isMobile ? "btn--primary nav-button-next xs:w-full sm:w-full " : "btn--primary nav-button-next pull-right  w-2/5"} >
    //                 <span>Continue</span>
    //             </button>
    //         </div>
    //     </PopupModal>
    // }

    return (
        loading ? null :
            <div className="container" style={{ padding: "1rem", width: "95%" }}>
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        {(showAlert && (alertMessage.title || alertMessage.message) &&
                            <Alert
                                title={alertMessage.title}
                                content={alertMessage.message}
                                closeAlert={() => clearAlertMessage()}
                            />
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12" >
                        <h2 className="page-header-center " style={{ padding: "0rem", marginBottom: "1rem" }}>
                            Select a business insurance type.
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div id="productSelection" style={{ padding: "1rem", marginBottom: ".1rem" }} className="image-selection-wrapper">
                            <fieldset className="form-field" role="radiogroup" >
                                <div className="image-selections " style={{ justifyItems: "flex-start" }}>
                                    {commercialProducts && (
                                        commercialProducts.map((product, index) => (
                                            <ImageSelectionCard
                                                key={product.id}
                                                id={product.id}
                                                name={product.lobCode}
                                                disabled={product.disabled}
                                                label={product.label}
                                                show={true}
                                                image={process.env.PUBLIC_URL + "/assets/" + product.image}
                                                description={product.description}
                                                items={product.items}
                                                selected={product.selected}
                                                error={productError !== ""}
                                                onClick={() => setProduct(product)}
                                            />
                                        ))
                                    )}
                                </div>
                                {productError && productError !== "" && <span className="form-field-wrapper error-message form-message"> {productError}</span>}

                            </fieldset>
                        </div>
                    </div>
                </div>
                {/* {redirectToPartner !== "" ? showConsent() : null} */}
                <div className="row" >
                    <div className="col-sm-12 col-md-6">
                        {/*Enable in case we have to shod back on this screen*/}
                        <Button style={{ display: "none" }}
                            className="btn--secondary nav-button-back w-1/5 xs:w-full sm:w-full"
                        >Back</Button>
                    </div>
                    <div className="col-sm-12 col-md-6 pull-left" style={deviceDetail.isMobile ? { border: "0px", position: "fixed", backgroundColor: "#fafafa", bottom: "0px", left: "0px", width: "100%", padding: ".5rem", zIndex: "5", alignItems: "center" } : {}} >
                        <Button
                            disabled={(loading || formError)}
                            className="btn--primary nav-button-next mt-10  w-3/5 xs:w-full sm:w-full"
                            onClick={(e) => submitForm(e)}
                        >Next</Button>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-sm-12 col-md-12" style={{ marginBottom: "40px" }}>
                        <Disclaimer
                            headerLabel="Legal Notices"
                            label="The information you provide will be shared with selected insurance companies to obtain a policy. If GEICO is unable to match you with one of our trusted partners, you may opt to be referred to Commercial Insurance Center (&#8220;CIC&#x201C;), to help find a commercial insurance solution for your business. Your information will not be shared with CIC without your consent." />
                    </div>
                    <div className="col-sm-12 col-md-12">
                    </div>
                </div>
            </div>
    );
};

