import { Notice } from "./search-quote-response";

export const AGENT_NOTES_RESPONSE = "AGENT_NOTES_RESPONSE"
export const AGENT_NOTES_REQUEST = "AGENT_NOTES_REQUEST"

export type AgentNotesData = {
    quoteInfoId: string;
    agentId: string;
    agentName: string;
    callReason: string;
    note: string;
    createdDate: string;
}

export type AgentNotesResponse = {
    type: typeof AGENT_NOTES_RESPONSE;
    data: {
        partnerQuoteNotes: AgentNotesData[];
        notices: Notice[];
    };
};

export type AgentNotesRequest = {
    quoteInfoId: string
}

export type AddAgentNoteRequest = {
    quoteInfoId: string;
    agentId: string;
    agentName: string;
    callReason: string;  
    note: string;
    createdDate: string;
}