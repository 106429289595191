import React, { FC, PropsWithChildren, useEffect, useLayoutEffect, useRef } from "react";
import { GdkModal } from "@gdk/modal";

type PopupModalProps = {
    modalId: string,
    showModal: boolean,
    onClosed: Function,
    modalType: "dialogueBox" | "fullScreen",
    autoShow: boolean,
    overlayShouldCloseModal: boolean,
    showCloseButton: boolean,
    modalHeadline: string,
    isBackNav: boolean,
    children?: React.ReactNode;
}

export const PopupModal: React.FC<PopupModalProps> = (props: PopupModalProps) => {
    const gdkModal = useRef<GdkModal>();
    const modalIdRef = useRef(props.modalId),
        onClosedRef = useRef(props.onClosed),
        modalTypeRef = useRef(props.modalType),
        autoShowRef = useRef(props.autoShow),
        overlayShouldCloseModalRef = useRef(props.overlayShouldCloseModal),
        showCloseButtonRef = useRef(props.showCloseButton);

    useLayoutEffect(() => {
        if (!gdkModal.current) {
            gdkModal.current = new GdkModal({
                content: "#" + modalIdRef.current,
                modalType: modalTypeRef.current,
                autoShow: autoShowRef.current,
                overlayShouldCloseModal:
                    typeof overlayShouldCloseModalRef.current === "boolean"
                        ? overlayShouldCloseModalRef.current
                        : true,
                hideCloseButton:
                    showCloseButtonRef.current ||
                        showCloseButtonRef.current === undefined
                        ? false
                        : true,
                onClosed: () => {
                    onClosedRef.current();
                },
            });
        }
    }, []);

    useEffect(() => {
        if (props.showModal) {
            gdkModal.current?.show();
        } else {
            gdkModal.current?.hide();
        }
    }, [props.showModal]);

    useEffect(() => {
        // Ensures action modals are closed on back navigation
        if (props.isBackNav) {
            gdkModal.current?.hide();
        }
    }, [props.isBackNav]);

    return (
        <div id={props.modalId} className="modal" data-link-name="data-modal-view">
            <div className="modal-container">
                {props.modalHeadline ? (
                    <h3 className="modal-headline">{props.modalHeadline}</h3>
                ) : null}
                <div className="modal-content">{props.children}</div>
            </div>
        </div>
    );
};

