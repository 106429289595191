import React from "react";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    className?: string;
    value?: string;
    disabled?: boolean;
    required?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    labelClass?: string;
    containerClass?: string;
    error?: string;
    inputType?: "text" | "select";
    options?: { value: string; label: string }[];
};
export const SearchQuoteInput: React.FC<Props> = ({
    className = "col-md-12",
    label,
    value,
    disabled,
    required,
    onChange,
    placeholder,
    labelClass = 'text',
    error,
    containerClass = "col-md-12",
    inputType,
    options = [],
    ...props
}) => {
    return (
        <div className={
            error
                ? `${containerClass} ${labelClass} form-field--error form-message`
                : `${containerClass} ${labelClass} form-field`
            }
        >          
            {label && (
                <label htmlFor={props.name} className={`text ${labelClass}`}>
                    {label}
                </label>
            )}
            {inputType === "select" ? (
                // Render select input if inputType is select
                <select
                    value={value}
                    disabled={disabled}
                    required={required}
                    onChange={onChange as unknown as (e: React.ChangeEvent<HTMLSelectElement>) => void}                    
                >
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : (
                // Render text input for other cases
                <input
                    value={value}
                    disabled={disabled}
                    required={required}
                    onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
                    placeholder={placeholder}                    
                    type={inputType}
                    {...props} 
                />
            )}            
            {error && <span className="form-message">{error}</span>}                        
        </div>
    );
};

export default SearchQuoteInput;