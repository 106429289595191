import {
  SystemActionTypes,
  SystemState,
  MSAL_SET_USER_TYPE,
  UserType
} from "./types";


export const SystemReducer = (
    state: SystemState = { isLoggedIn: true, userName: null, userType: UserType.Customer },
    action: SystemActionTypes
): SystemState => {
    if (action.type === MSAL_SET_USER_TYPE) {
        return {
            ...state,
            userType: action.userType,

        }
    }
    return {
        ...state
    };    
};