import { LogLevel, Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: "6ecec6b7-4a78-42eb-bad1-4ac94ff35d98",
        authority: "https://login.microsoftonline.com/7389d8c0-3607-465c-a69f-7d4426502912",
        redirectUri: "http://localhost:49293"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level:any, message:any, containsPii:any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
                default:
                }
            }
        }
    }
};

export const loginRequest = {
    scopes:["User.Read", "User.Read.All"]
}
