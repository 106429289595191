import { SaveQuoteResponse } from "../../api/types";
import { CommercialQuote, SaveQuoteRequest } from "../../api/types/quote-request";
import { CLEAR_CACHE, CLEAR_ERROR, QuoteActionTypes, INIT_QUOTE_CACHE, SET_ERROR, SET_LOADING, Update_QUOTE_RESPONSE, Update_QUOTE_DATA } from "./types";

export const InitQuoteCache = (request: SaveQuoteRequest): QuoteActionTypes => ({
    type: INIT_QUOTE_CACHE,
    quote: request
})

export const updateQuoteResponse = (response: SaveQuoteResponse): QuoteActionTypes => ({
    type: Update_QUOTE_RESPONSE,
    response: response
})

export const updateQuote = (data: CommercialQuote): QuoteActionTypes => ({
    type: Update_QUOTE_DATA,
    quote: data
})

export const setDBLoading = (isLoading: boolean): QuoteActionTypes => ({
    type: SET_LOADING,
    isLoading:isLoading
})

export const setError = (errorMessage: string): QuoteActionTypes => ({
    type: SET_ERROR,
    errorMessage: errorMessage
})

export const clearError = (): QuoteActionTypes => ({
    type: CLEAR_ERROR    
})

export const clearCache = (): QuoteActionTypes => ({
    type: CLEAR_CACHE
})