////export const autocomplete = (value: string, items: string[] | undefined, setSuggestions: Function) => {
////    if (!items) return;
////    let suggestions = [];
////    if (value.length > 0) {
////        const regex = new RegExp(`${value}`, 'i');
////        suggestions = items.sort().filter(v => regex.test(v));
////        setSuggestions(suggestions.splice(0, 7))
////    }

////    if (value.length === 0) {
////        setSuggestions('')
////    }
////}

export const excludeSpecialCharacters = (value: string): string => {
    if (!value) {
        return value;
    }
    return value.replace(/[^\w\s-.,]/gi, '');
}

export const digitsOnly = (value: string) => {
    if (!value) {
        return value
    }
    return value.replace(/[^0-9.]+/g, '');
}

export const isEmailValid = (value: string) => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(value);        
}