import React, { useEffect, useRef } from "react";
import { GdkLoader } from "@gdk/loader";
import { usePageLoadingContext } from "../contexts/PageLoadingContext";

const SectionLoader = () => {

    const gdkLoaderRef = useRef<GdkLoader>();
    const { pageState } = usePageLoadingContext();

    useEffect(() => {
        if (!gdkLoaderRef.current) {
            gdkLoaderRef.current = new GdkLoader({
                content: "#common-loader",
                type: "section"
            });
        }
    }, []);

    useEffect(() => {
        if (pageState.sectionLoading) {
            gdkLoaderRef?.current?.show();
        } else {
            gdkLoaderRef.current?.hide();
        }
    }, [pageState]);
    
    return (
        <div style={{ display: 'block' }}>
            <div id="common-loader" className="loader-container">
                <div className="loader-content">
                    <div className="loader loader--animated" aria-label="Please wait while your request is being processed">
                     <div className="animated-loader-copy">
                             Please wait while your request is being processed
                            <div className="ellipses"><span className="ellipses-1">.</span><span className="ellipses-2">.</span><span className="ellipses-3">.</span></div>
                        </div>                                                
                    </div>                   
                </div>
            </div>
        </div>
    );
};

export default SectionLoader;