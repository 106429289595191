import { useClientConfigs } from "./contexts/ClientConfigContext";
import { PublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./config/authConfig";
import { useEffect, useState } from "react";
import { AuthContextProvider, TokenClaims } from "./contexts/AuthContextProvider";
import { UserType, PageType } from "./store/system/types";
import { App } from "./App";
import { useMsalHelper } from "./lib/useMsalHelper";
import axios from "axios";
import { UserInfo } from "./api/types/auth-login-request";
import { usePageLoadingContext } from "./contexts/PageLoadingContext";
import { Error } from "./pages/Error/Error";
import { useApi } from "./api";

export const Main = () => {
    var api = useApi();
    const useConfig = useClientConfigs();
    const { hideLoader } = usePageLoadingContext();
    const [renderUi, setRenderUi] = useState<PageType>(PageType.None);
    const [userInfo, setUserInfo] = useState<UserInfo>({
        userName: "",
        employeeId: "LPCustomer",
        userType: UserType.Customer,
        userRoles: [],
        authToken: "",
    });
    msalConfig.auth.clientId = useConfig.adClientId;
    msalConfig.auth.redirectUri = window.location.origin;
    msalConfig.auth.authority = useConfig.adInstance;

    const msalInstance = new PublicClientApplication({
        auth: msalConfig.auth,
        cache: msalConfig.cache,
    });

    const { handleMsalEventCallback, hadnleMsalRedirectPomise } =
        useMsalHelper(msalInstance);

    const renderUiCallback = (pageType: PageType): void => {
        if (pageType === PageType.UI) {
            getEmployeeId(msalInstance)
                .then((res) => {
                    setRenderUi(PageType.UI);
                })
                .catch(() => {
                    setRenderUi(PageType.Error);
                });
        } else {
            hideLoader();
            setRenderUi(pageType);
        }
    };

    useEffect(() => {
        if (useConfig?.userType === "Agent") {
            const accounts: Array<AccountInfo> = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                msalInstance.setActiveAccount(accounts[0]);
                getEmployeeId(msalInstance)
                    .then((res) => {
                        setRenderUi(PageType.UI);
                    })
                    .catch(() => {
                        setRenderUi(PageType.Error);
                    });
            }

            handleMsalEventCallback(renderUiCallback);
            hadnleMsalRedirectPomise(renderUiCallback);

            const tokenRenewalInterval = setInterval(() => {
                renewToken(msalInstance);
            }, 60 * 60 * 1000); 

            return () => clearInterval(tokenRenewalInterval);
        } else if (useConfig?.userType === "Customer") {
            setRenderUi(PageType.UI);
        }
    }, [useConfig]);

    const getEmployeeId = async (msal: PublicClientApplication): Promise<any> => {
        try {
            var token = await msal.acquireTokenSilent(loginRequest);
            const claims = token.idTokenClaims as TokenClaims; 
            var response = await axios.get(useConfig.graphUrl, {
                headers: { Authorization: "Bearer " + token.accessToken },
            });
            setUserInfo({
                ...userInfo,
                userName: response.data.givenName + " " + response.data.surname,
                employeeId: response.data.employeeId,
                userType: UserType.Agent,
                userRoles: claims?.roles ?? [],
                authToken : token.idToken
            });
        } catch (ex: any) {
            api.commercialAgentApi
                .logError({
                    ErrorMessage: "Error while fetching user details from graph api",
                    Title: "Graph api error",
                    ErrorDetails: ex.message,
                })
                .then(() => { })
                .catch((error) => {
                    console.log(error);
                });
            console.log(ex);
            hideLoader();
            throw ex;
        }
    };

    const renewToken = async (msal: PublicClientApplication): Promise<void> => {
        try {
            var token = await msal.acquireTokenSilent(loginRequest);
            console.log("Token renewed, User can continue session");
            setUserInfo((prevUserInfo) => ({
                ...prevUserInfo,
                authToken: token.idToken,
            }));
        } catch (ex: any) {
            console.log("Token renewal failed:", ex);
        }
    };

    const render = (
        <AuthContextProvider msal={msalInstance} userInfo={userInfo}>
            <App></App>
        </AuthContextProvider>
    );

    if (renderUi === PageType.Error) {
        return <Error></Error>;
    }
    return renderUi === PageType.UI ? render : <></>;
};