export const storage = {
    save: (key: string, data: any, expireMin?: number) => {
        const entry = {
            value: JSON.stringify(data),
            timestamp: expireMin ? Date.now() + expireMin * 60 * 1000 : false,
        }
        try {           
            sessionStorage.setItem(key, JSON.stringify(entry))
        } catch {
            console.warn(`Error saving '${key}' to sessionStorage`, { data })
        }
        return data
    },
    load: (key: string) => {
        try {
            const entry = JSON.parse(sessionStorage.getItem(key) ?? '')
            if (!entry) return false
            if (entry.timestamp) {
                return Date.now() < entry.timestamp && JSON.parse(entry.value)
            }
            return JSON.parse(entry.value)
        } catch {
            console.warn(`Error retrieving '${key}' from sessionStorage`)
            return false
        }
    },   
    remove: (key: string): boolean => {
        try {
            sessionStorage.removeItem(key);
        } catch {
            return false
        }      
        return true
    },
    clear: sessionStorage.clear,
    exist: (key: string): boolean => {
        try {
            const entry = JSON.parse(sessionStorage.getItem(key) ?? '')
            if (!entry) return false
            if (entry.timestamp && Date.now() < entry.timestamp ) {
                return true;
            }
            return false;
            
        } catch {
            console.warn(`Error retrieving '${key}' from sessionStorage`)
            return false
        }
    },
}