export const PARTNER_DETAILS_RESPONSE = "PARTNER_DETAILS_RESPONSE";

export type PartnerDetails = {
    code: string;
    name: string;
    imageUrl: string;
    redirectUrl: string;
};


export type PartnerDetailsResponse = {
    type: typeof PARTNER_DETAILS_RESPONSE;
    data: PartnerDetails[];
};