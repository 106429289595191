export const SAVE_QUOTE_RESPONSE = "SAVE_QUOTE_RESPONSE"

export interface QuoteResponseData {   
    launchpadId: string;
    redirectUrl: string; 
    success: boolean;
    message: string;   
}

export interface SaveQuoteResponse {
    type: typeof SAVE_QUOTE_RESPONSE;
    data: QuoteResponseData
}
