export const STATE_LOOKUP_RESPONSE = "STATE_LOOKUP_RESPONSE"

export type StateDataModel = {
    state: string,
    success: boolean,
    zip: string,
    errorMessage: string
}

export type StateLookupResponse = {
    type: typeof STATE_LOOKUP_RESPONSE;
    data: StateDataModel;
};

export const STATE_LOOKUP_REQUEST = "STATE_LOOKUP_REQUEST"

export type StateLookupRequest = {
    zipCode: string
}
