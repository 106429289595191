import { combineReducers, createStore } from 'redux';
import { QuoteReducer } from './quote/reducers'
import { SystemReducer } from './system/reducers'
import { IQuoteState } from './quote/types';

const rootReducer = combineReducers({
    quote: QuoteReducer,
    system: SystemReducer
});

export interface IRootState {
    QuoteState: IQuoteState
}

export type RootState = ReturnType<typeof store.getState>

const store = createStore(
    rootReducer,
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store