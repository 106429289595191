export const UPSERT_PARNTER_QUOTE_INFO_RESPONSE = "UPSERT_PARNTER_QUOTE_INFO_RESPONSE"

export type QuoteInfoResponse = {
    success: boolean;
    notices: Notice[];
};

export type Notice = {
    id: string;
    correlationId: string;
    severity: string;
    description: string;
    source: string;
    propertyName: string;
};

export const QutoeInfoErrorResponse : Notice[] = [];

export type UpsertPartnerQuoteInfoResponse = {
    type: typeof UPSERT_PARNTER_QUOTE_INFO_RESPONSE;
    data: {
        quoteInfo: QuoteInfoResponse;
        notices: Notice[];
    };
};