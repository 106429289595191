export const Error = () => {
  return (
    <div className="main-wrapper">
      <a className="skip-to-content" href="#wrapper">
        Skip to main content
      </a>
      <header id="primary-header" role="banner">
        <div className="header-logo">
          <a className="icon-geico" aria-label="GEICO Home" href="/"></a>
        </div>
        <div className="header-links"></div>
      </header>
      <main id="wrapper" role="main" className="bg-color--cloudy">
        <section className="page-header--wrapper margin-bottom">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-header page-header--without-subheader">
                  <div className="page-header-left">
                    <h2 className="font--bold">Error</h2>
                  </div>
                  <div className="page-header-right">
                    <span className="geico-icon icon-alert pull-right"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="hidden-sm col-md-5 col-lg-3 col-lg-offset-1">
              <img
                src={process.env.PUBLIC_URL + "/geckoPose.png"}
                className="error-gecko"
                alt="gecko"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-8">
              <div className="encounter-error-message">
                <div className="section-header">
                  <div className="section-header-title-subtext">
                    <h2 className="font--bold ">
                      Oops! Seems we've encountered an error.
                    </h2>
                    <p>
                      {" "}
                      <span> We apologize for the inconvenience.</span>
                      <span>
                        {" "}
                        <br />{" "}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="section-header"></div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
