export const CARRIER_RECOMMENDATION_RESPONSE = "CARRIER_RECOMMENDATION_RESPONSE"
export const MULTIPLE_CARRIER_RECOMMENDATION_RESPONSE = "MULTIPLE_CARRIER_RECOMMENDATION_RESPONSE"

export type CarrierRecommendationResponse = {
    type: typeof CARRIER_RECOMMENDATION_RESPONSE;
    data: CarrierRecommendationModel;
};

export type CarrierRecommendationModel = {
    carrier: string,
    businessClass: string    
}

export type MultipleCarrierRecommendationResponse = {
    type: typeof MULTIPLE_CARRIER_RECOMMENDATION_RESPONSE;
    data: RecommendedCarrier[];
};

export type RecommendedCarrier = {
    carrier: string;
    label: string;
    businessClass: string;
    active: boolean;
    order: number;
    imageUrl: string;
    redirectUrl: string;   
    isBoldPenguinOverride: boolean;
}


export const InitCarrierRecommendationModel = (): CarrierRecommendationModel => {
    return {
        carrier: "",
        businessClass: ""         
    };
}

export const InitMultipleCarrierRecommendationModel = (): RecommendedCarrier[] => {
    return [];
}