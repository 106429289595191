import React, { useState, useEffect, createContext, useReducer, FC, useContext } from 'react';
import { IQuoteState, QuoteState } from '../store/quote/types';
import { useClientConfigs } from './ClientConfigContext';

export interface IPageLoadingContext {
    pageState: IPageState,
    showLoader: () => void,
    hideLoader: () => void
}

export interface IPageState {
    pageName: string,
    data: IQuoteState,
    back: boolean,
    disableBack: boolean,
    submitPending: boolean,
    sectionLoading: boolean
}

const defaultState: IPageState = {
    pageName: "",
    back: false,
    data: new QuoteState(),
    submitPending: true,
    disableBack: false,
    sectionLoading: true,
};

const PageLoadingContext = React.createContext<IPageLoadingContext>({
    pageState: defaultState,
    hideLoader: () => { },
    showLoader: () => { }
});

const reducer = (state: IPageState, action:any) => {
    switch (action.type) {
        case "page-processing-pending":
            return {
                ...state,
                submitPending: true,
                disableBack: false,
                sectionLoading: false,
                back: false,
                data: null,
            };
        case "page-processing-success":
            // back is not set to false for "page-processing-success" on purpose.
            // back indicates that the user is currently viewing the back page.
            // back is used in Form.js to prevent a previous page request from overriding the display of the back page.
            return {
                ...state,
                submitPending: false,
                disableBack: false,
                sectionLoading: false,
                data: action.data,
            };
        case "back-processing-success":
            return {
                ...state,
                back: true,
                pageName: action.pageName,
            };
        case "show-loading":
            return {
                ...state,
                back: false,
                disableBack: true,
                sectionLoading: true,
            };
        case "hide-loading":
            return {
                ...state,
                back: false,
                disableBack: true,
                sectionLoading: false,
            };
        default:
            return state;
    }
};

const PageLoadingContextProvider: FC<{
    children?: React.ReactNode;
}> = ({ children}) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const clientConnfig = useClientConfigs();

    useEffect(() => {
        if (clientConnfig.configLoaded) {
            actions.hideLoader();
        }
        else {
            actions.showLoader();
        }
    }, [clientConnfig])

    const actions = {
        showLoader: () => {
            {
                if (!state.sectionLoading) {
                    dispatch({ type: "show-loading", state });
                }
            }
        },
        hideLoader: () => {
            {                
                dispatch({ type: "hide-loading", state });
            }
        }
    };
    return (
        <PageLoadingContext.Provider value={{ pageState: state, showLoader: actions.showLoader, hideLoader: actions.hideLoader }}>
            {children}
        </PageLoadingContext.Provider>
    );
}

const usePageLoadingContext = (): IPageLoadingContext => {
    const context = React.useContext(PageLoadingContext);

    if (!context) {
        throw new Error(
            "usePageLoadingContext must be used within a PageLoadingContext"
        );
    }

    return context;
}
export { PageLoadingContextProvider, usePageLoadingContext};