
export type SearchQuoteRequest = {
  referenceNumber: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  emailId: string;
  phoneNumber: string;
  zipCode: string;
  appName: string;
  userType: string;
}
export const InitialSearchInputs = (): SearchQuoteRequest => {
  return {
    referenceNumber: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    emailId: "",
    phoneNumber: "",
    zipCode: "",
    appName: "",
    userType: ""
  };
}

export const InitialInputErrorMessages = (): SearchQuoteRequest => {
  return {
    referenceNumber: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    emailId: "",
    phoneNumber: "",
    zipCode: "",
    appName: "",
    userType: ""
  };
}