import React, { useEffect, useState, useLayoutEffect } from "react";
import { BusinessKeywordModel } from "../api/types/business-keyword-model";
import { useDebounce } from "../hooks/useDebounce";

type BusinessKeywordAutoCompleteProps = React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    labelClass?: string;
    className?: string;
    containerClass?: string;
    error?: string;
    suggestionClass?: string;
    suggestionsContainerClass?: string;
    suggestions?: Array<BusinessKeywordModel>;
    defaultValue: string;
    onItemChange: (selectedItem: BusinessKeywordModel | null) => void;
    innerRef?: React.RefObject<HTMLInputElement>;
    disabled?: boolean;
};

export const BusinessKeywordAutoComplete: React.FunctionComponent<BusinessKeywordAutoCompleteProps> = ({
    label,
    labelClass,
    className = "w-2/5 sm:w-4/5 xs:w-full",
    containerClass = "col-md-12 mb-3p5rem",
    error,
    suggestionClass = "w-full",
    suggestionsContainerClass = "w-2/5 sm:w-4/5 xs:w-full autocomplete-suggestions-container",
    suggestions,
    defaultValue = "",
    onItemChange,
    innerRef,
    disabled,
    ...props
}) => {

    const [scrollIndex, setScrollIndex] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState<BusinessKeywordModel[]>();
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [defaultDescription, setDefaultDescription] = useState("");
    // Search term
    const [searchInputText, setSearchInputText] = useState("");
    const debouncedKeywordSearch: string = useDebounce<string>(searchInputText, 400);
    const refDropDownItem = React.createRef<HTMLLIElement>();
    const refInputSearch = innerRef ?? React.createRef<HTMLInputElement>();

    const onChange = (e: any) => {
        const userInput = e.currentTarget.value;
        setSearchInputText(userInput);
        onItemChange(null);
    };

    const onClick = (e: any) => {
        setShowSuggestions(true);
    };

    useLayoutEffect(() => {
        setShowSuggestions(false);
        let defaultKeywordIndex = suggestions?.findIndex(k => k.id === defaultValue);
        setFilteredSuggestions(suggestions);
        if (suggestions && defaultKeywordIndex && defaultKeywordIndex >= 0) {
            setDefaultDescription(suggestions[defaultKeywordIndex].description ?? "");
            setScrollIndex(defaultKeywordIndex);
            setScroll();
        }
    }, [suggestions]);

    useEffect(() => {
        setScroll();
        setShowSuggestions(false);
        let newSuggestions = suggestions?.filter(v => {
            var index = v.description.toLowerCase().indexOf(debouncedKeywordSearch.trim().toLowerCase());
            if (index >= 0) {
                v.index = index;
                return v;
            }
        }).sort((a, b) => a.index - b.index);
        setScrollIndex(0);
        setFilteredSuggestions(newSuggestions);
        setShowSuggestions(true);

    }, [debouncedKeywordSearch]);

    useEffect(() => {
        setScrollIndex(0);
        if (searchInputText?.length >= 0 && suggestions && suggestions.length > 0) {
            setShowSuggestions(true);
        }
        else {
            setShowSuggestions(false);
        }
    }, [searchInputText]);

    useEffect(() => {
        if (showSuggestions) {
            setScroll();
        }
    }, [scrollIndex]);

    const setScroll = () => {
        refDropDownItem.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }

    const onInputKeyDown = (e: any) => {
        let key = e.key;
        if (!showSuggestions) {
            setShowSuggestions(true);
            return;
        }

        if (e.key === "ArrowUp" || key === "ArrowDown") {
            e.preventDefault();
        }
        if (filteredSuggestions === undefined || filteredSuggestions.length <= 0) {
            setShowSuggestions(true);
            return;
        }

        if (key === "ArrowUp" && scrollIndex === 0) {
            setScrollIndex(filteredSuggestions.length - 1);
            setShowSuggestions(true);
            return;
        }

        if (key === "ArrowDown" && scrollIndex === filteredSuggestions.length - 1) {
            setScrollIndex(0);
            setShowSuggestions(true);
            return;
        }

        if (key === "ArrowLeft" && filteredSuggestions.length > 0) {
            setShowSuggestions(true);
            return;
        }

        if (key === "ArrowRight" && filteredSuggestions.length > 0) {
            setShowSuggestions(true);
            return;
        }

        if (key === "ArrowUp" && scrollIndex < filteredSuggestions.length && scrollIndex > 0) {
            setScrollIndex(scrollIndex - 1);
            setShowSuggestions(true);
            return;
        }
        if (key === "ArrowDown" && scrollIndex < filteredSuggestions.length - 1) {
            setShowSuggestions(true);
            setScrollIndex(scrollIndex + 1);
            return;
        }
        if (key === "Enter") {
            if (showSuggestions) {
                onListItemSelect(scrollIndex);
            }
            else {
                setShowSuggestions(true);
            }
            return;
        }

        if (key === "Escape") {
            setShowSuggestions(false);
        }
    };

    const onListItemSelect = (index: number): void => {
        if (filteredSuggestions && filteredSuggestions.length > 0) {
            setScrollIndex(index);
            let selectedItem: BusinessKeywordModel = filteredSuggestions[index];
            if (refInputSearch.current && selectedItem) {
                refInputSearch.current.value = selectedItem.description ?? "";
                onItemChange(selectedItem);
                setShowSuggestions(false);
            }
        }
    }

    return (
        <div
            className={
                error ? `${containerClass} form-field--error` : `${containerClass}`
            }
        >
            {label && (
                <label htmlFor={props.name} className={`text ${labelClass}`}>
                    {label}
                </label>
            )}

            <div className={`search-container`}>
                <input
                    {...props}                   
                    onFocus={() => setShowSuggestions(true)}
                    onChange={(e) => onChange(e)}
                    ref={refInputSearch}
                    onKeyDown={(e) => onInputKeyDown(e)}
                    defaultValue={defaultDescription}
                    disabled={disabled}
                    className={
                        disabled ? `mb-0 ${className} input-disabled` : `mb-0 ${className} `
                    }
                    onBlur={(e) => setShowSuggestions(false)}
                    onClick={(e) => onClick(e)}
                />
            </div>
            {filteredSuggestions && filteredSuggestions?.length > 0 && (
                <ul className={showSuggestions ? suggestionsContainerClass : "none"}>
                    {
                        filteredSuggestions?.map((item, index) => {
                            return (
                                <li
                                    className={`autocomplete-input-suggestion ${suggestionClass ?? className
                                        } ${index === scrollIndex ? "hovered-suggestion" : ""}`}
                                    key={index}
                                    ref={index === scrollIndex ? refDropDownItem : props.itemRef}
                                    onMouseDown={() => onListItemSelect(index)}
                                > {item.description}
                                </li>
                            )
                        })}
                </ul>
            )}
            {error && <span className="form-message">{error}</span>}
        </div>
    );
};
