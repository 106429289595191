import { usePageLoadingContext } from "../../contexts/PageLoadingContext";
import { useEffect } from 'react'
export const UnAuthorised = () => {
    const { showLoader } = usePageLoadingContext();
    useEffect(() => {
        showLoader();       
        window.location.href = window.location.origin;
        },
        []);
    return (<></>);
}